import { useTracker } from '@archipro-website/tracker';
import type React from 'react';
import { useCallback } from 'react';
import { isExternalURL, urlEventDiscovery } from '~/utils/urlHelper';
import { enhanceProfessionalWebsiteLink } from '../util/websiteTracking';
import { useNavigate } from '@remix-run/react';

interface TrackableItemType {
    professionalID?: number;
    professionalTitle?: string;
    itemType?: string;
    link?: string;
}

interface WebsiteTrackingProxyOptions {
    attachUTM?: boolean;
    processRelativeURL?: boolean;
    preventOpenLink?: boolean;
}

export const useWebsiteTrackingProxy = (
    item: TrackableItemType = {},
    options: WebsiteTrackingProxyOptions = {}
): React.EventHandler<React.MouseEvent> => {
    const tracker = useTracker();
    const navigate = useNavigate();
    const { attachUTM = true, processRelativeURL, preventOpenLink } = options;
    const {
        professionalID,
        professionalTitle,
        itemType,
        link: professionalLink,
    } = item;

    return useCallback(
        (e: React.MouseEvent) => {
            if (e.isPropagationStopped()) {
                return;
            }

            const link = urlEventDiscovery(e);
            if (!link) {
                return;
            }

            let href = link.getAttribute('href') || '';
            const openInNewTab = link.getAttribute('target') === '_blank';
            const isExternal = isExternalURL(href);

            e.preventDefault();
            e.stopPropagation();

            if (preventOpenLink) {
                return;
            }

            // If the link is not external, we let react-router handle the navigation
            if (!isExternal) {
                const linkTarget = new URL(href, window.location.origin);
                const pathname = linkTarget.pathname;
                if (!processRelativeURL || !professionalLink) {
                    if (openInNewTab) {
                        window.open(pathname);
                    } else {
                        navigate(pathname);
                    }
                    return;
                }

                const redirectURL = new URL('/forward', window.location.origin);

                // If the link is relative, we use `/redirect` to process the URL
                const urlWithUTM =
                    enhanceProfessionalWebsiteLink(linkTarget.href, true) || '';

                redirectURL.searchParams.append('link', professionalLink);

                if (urlWithUTM) {
                    const url = new URL(urlWithUTM);
                    const qs = url.searchParams.toString();
                    if (qs) {
                        redirectURL.searchParams.append('qs', qs);
                    }
                    redirectURL.searchParams.append('path', url.pathname);
                } else {
                    redirectURL.searchParams.append('path', pathname);
                }

                if (openInNewTab) {
                    window.open(redirectURL.href);
                } else {
                    location.href = redirectURL.href;
                }

                return;
            }

            if (attachUTM) {
                href = enhanceProfessionalWebsiteLink(href, true) || '';
            }

            const data = {
                ExternalURL: href,
                itemType,
                professionalID,
                professionalTitle,
            };

            tracker.log('Website', {
                url: new URL(window.location.href),
                data: data,
            });
            if (openInNewTab) {
                window?.open(href);
            } else {
                location.href = href;
            }
        },
        [
            professionalID,
            professionalTitle,
            itemType,
            attachUTM,
            tracker,
            navigate,
            processRelativeURL,
            professionalLink,
            preventOpenLink,
        ]
    );
};
